
  
  /* @font-face {
    font-family: 'Candal SPECIAL';
    src: url('./fonts/GT-Flexa-X-Compressed-Medium.woff2') format('truetype');
  }

  @font-face {
    font-family: 'Candal';
    src: url('./fonts/GT-Flexa-X-Compressed-Bold-Trial.woff2') format('truetype');
  } */

  /* @font-face {
    font-family: 'Candal';
    src: url('./fonts/GT_Flexa_VAR.ttf') format('truetype');
  } */

  @font-face {
    font-family: 'Candal';
    src: url('./fonts/FlexaVAR.woff2') format('truetype');
  }
  
  /* Repeat for other fonts, e.g., KHGiga-Black */
  @font-face {
    font-family: 'KHGiga';
    src: url('./fonts/KHGiga-Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'KHTeka';
    src: url('./fonts/KHTeka-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'KHTeka';
    src: url('./fonts/KHTeka-BoldItalic.otf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'KHTeka';
    src: url('./fonts/KHTeka-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'KHTeka';
    src: url('./fonts/KHTeka-RegularItalic.otf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Candal';
    src: url('./fonts/Candal-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  